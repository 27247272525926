/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

:root {
    --slider-crumb-color: #fff;
    --slider-crumb-background: #000;
}

.Slider {
    --translateX: 0;
    --animation-speed: 0;
    --slider-height: 0;

    max-width: 100%;
    overflow: hidden;
    touch-action: pan-y;

    transition: height 300ms;

    &-Wrapper {
        display: flex;
        align-items: flex-start;
        height: 100%;

        will-change: transform;
        transition: transform var(--animation-speed);
        transform: translate3d(var(--translateX), 0, 0);

        > * {
            flex-grow: 1;
            flex-shrink: 0;
            width: 100%;
            height: 100%;
        }
    }

    &-Crumbs {
        position: absolute;
        margin: auto;
        display: grid;
        grid-gap: 10px;

        width: 100%;
        grid-template-columns: repeat(auto-fit, 7px);
        justify-content: center;
        bottom: 1rem;
    }

    &-Crumb {
        width: 7px;
        height: 7px;
        border-radius: 50%;
        background-color: var(--slider-crumb-background);
        border: 1px solid var(--slider-crumb-color);
        transition: background-color 300ms;

        &_isActive {
            background-color: var(--slider-crumb-color);
            border-color: var(--slider-crumb-background);
        }
    }
}
