/* 📌Note: using BEM convention in this css */

[data-is-active='no'] :hover {
  transform: scale(1.01);
}

[data-type='icon'] {
  position: absolute;
  opacity: 0.4;
  top: 50%;
  transform: translateY(-50%);
  z-index: 40;
  cursor: pointer;
}

[data-is-active='yes'] img {
  opacity: 1;
}

._3Wuwv {
}

._H-lxM {
  position: absolute;
  opacity: 0.4;
  top: 50%;
  transform: translateY(-50%);
  z-index: 40;
  cursor: pointer;
}

#_YAbQC {
  left: 30px;
}

#_2vt0K {
  right: 30px;
  display: none;
}

/*TAB LANDSCAPE*/
@media (max-width: 75em) {
}

/*TAB PORTRAIT*/
@media (max-width: 56.25em) {
}

/*PHONE*/
@media (max-width: 37.5em) {
}

/*EXTRA PHONE OPTIMIZATION*/
@media (max-width: 25em) {
}
