/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

:root {
    --cookiepopup-bg: #{$white};
    --cookiepopup-border: var(--secondary-base-color);
}

.CookiePopup {
    display: flex;
    width: 100vw;
    position: fixed;
    left: 0;
    bottom: 0;
    align-items: center;
    justify-content: space-between;
    padding: 1rem 0 calc(env(safe-area-inset-bottom) + 1rem);
    background-color: var(--cookiepopup-bg);
    border-top: 1px solid var(--cookiepopup-border);
    z-index: 300;

    &-ContentWrapper {
        @include after-mobile {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }
    }

    &-Wrapper {
        width: 100%;
    }

    &-Content {
        font-size: 15px;
        max-width: 500px;

        @include mobile {
            padding-bottom: 1rem;
        }
    }

    &-Link {
        display: inline;
        margin-left: 5px;
    }

    &-CTA {
        button {
            @include mobile {
                width: 100%;
            }
        }

        @include after-mobile {
            margin-left: 2rem;
        }
    }
}
