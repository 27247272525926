/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

$select-width: 160px !default;
$select-height: 45px !default;
$select-option-height: 36px !default;
$select-arrow-height: 6px !default;
$select-arrow-width: 6px !default;

:root {
    --select-option-background: #fff;
    --select-option-hover-background: #f0f0f0;
}

.FieldSelect {
    cursor: pointer;

    &::after {
        content: '';
        display: block;
        width: $select-arrow-width;
        height: $select-arrow-height;
        transform: translateY(-2px) rotate(45deg);
        pointer-events: none;

        position: absolute;
        right: 20px;
        bottom: 0;
        top: 0;
        margin: auto;

        border-style: solid;
        border-color: #000;
        border-width: 0 1px 1px 0;
    }

    &_isExpanded::after {
        @include desktop {
            bottom: $select-arrow-height * 3/2;
            transform: translateY(-2px) rotate(-135deg);
        }
    }

    &-Select {
        width: 100%;
        min-width: 100%;
        padding-right: 40px;

        @include desktop {
            pointer-events: none;
        }

        &_isExpanded {
            border-color: var(--input-color);
        }
    }

    &-Options {
        position: absolute;
        width: 100%;
        top: 100%;
        left: 0;
        z-index: 40;
        border-width: 0 1px 1px;
        border-style: solid;
        border-color: transparent;

        overflow-y: scroll;
        transition: 200ms max-height;
        will-change: max-height;
        max-height: 0;
        -webkit-overflow-scrolling: touch;

        &_isExpanded {
            @include desktop {
                max-height: 200px;
                border-color: var(--input-border-color);
            }
        }
    }

    &-Option {
        padding: 0;
        margin: 0;
        z-index: 1;
        transition: 100ms max-height ease-in;
        will-change: max-height;
        max-height: $select-option-height;
        line-height: 3rem;
        padding-left: 1.5rem;
        background: var(--select-option-background);

        &::before {
            content: none;
        }

        &:last-of-type {
            margin: 0;
        }

        &:hover,
        &:focus {
            @include desktop {
                background: var(--select-option-hover-background);
            }
        }
    }
}
