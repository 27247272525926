/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@mixin desktop {
    @media (min-width: 1025px) {
        @content;
    }
}

@mixin before-desktop {
    @media (max-width: 1024px) {
        @content;
    }
}

@mixin tablet {
    @media (max-width: 1024px) and (min-width: 768px) {
        @content;
    }
}

@mixin after-mobile {
    @media (min-width: 768px) {
        @content;
    }
}

@mixin mobile {
    @media (max-width: 767px) {
        @content;
    }
}

@mixin standalone {
    /* stylelint-disable-next-line media-feature-name-no-unknown */
    @media all and (display-mode: standalone) {
        @content;
    }
}
