/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

.NewVersionPopup {
    @include mobile {
        align-items: flex-end;
        height: 100%;
    }

    &-Heading {
        font-size: 20px;
        text-decoration: none;
        font-weight: 400;
        margin-top: 0;
        margin-bottom: 1rem;

        @include after-mobile {
            display: none;
        }
    }

    &-Button {
        margin-top: 1rem;

        @include mobile {
            width: 100%;
        }
    }

    &-Content {
        max-width: 500px;
    }

    .Popup {
        &-CloseBtn {
            display: none;
        }

        &-Heading {
            margin-bottom: 1rem;
        }
    }
}
