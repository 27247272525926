/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

@import '../../style/abstract/variables';
@import '../../style/abstract/media';
@import '../../style/abstract/button';
@import '../../style/abstract/loader';
@import '../../style/abstract/icons';
@import '../../style/abstract/parts';

/**
 * ScandiPWA - Progressive Web App for Magento
 *
 * Copyright © Scandiweb, Inc. All rights reserved.
 * See LICENSE for license details.
 *
 * @license OSL-3.0 (Open Software License ("OSL") v. 3.0)
 * @package scandipwa/base-theme
 * @link https://github.com/scandipwa/base-theme
 */

.SharedTransition {
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    position: fixed;
    z-index: 75;
    pointer-events: none;
    opacity: 0;

    &::after {
        content: '';
        position: absolute;
        display: block;
        height: calc(100vh - var(--header-height) - var(--navigation-tabs-height));
        width: 100%;
        top: var(--header-total-height);
        left: 0;
    }

    &_isVisible {
        @include mobile {
            pointer-events: all;
            opacity: 1;
            background: #fff;
        }
    }

    > * {
        padding: 0 !important;
        margin: 0 !important;
        left: 0 !important;
        top: 0 !important;

        width: var(--shared-element-width, 0) !important;
        height: var(--shared-element-height, 0) !important;
        margin-top: var(--shared-element-top, 0) !important;
        margin-left: var(--shared-element-left, 0) !important;

        will-change: width, height, margin-left, margin-top;
        transition-property: width, height, margin-left, margin-top;
        transition-timing-function: cubic-bezier(.215, .61, .355, 1);
        transition-duration: var(--shared-element-animation-speed, 150ms);

        backface-visibility: hidden;
        transform: translate3d(0, 0, 0);
        perspective: 1000px;
    }
}
